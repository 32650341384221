//import 'alpinejs';
//console.log("JS LOADED")


/*import GLightbox from 'glightbox';//Lightbox
const lightbox = GLightbox({
  touchNavigation: true,
  selector: 'data-lightbox',
  videosWidth: '80vw'
});
//console.log("JS LOADED") */

// Get Form Field

//var Flickity = require('flickity');


import 'mmenu-js';
// Mmmenu Configuration
document.addEventListener(
  "DOMContentLoaded", () => {
    const menu = new Mmenu("#mobile-menu", {
      extensions: ["position-right", "pagedim-black", "shadow-page", "theme-light", "position-front"],
      screenReader: {},
      navbars: [{
        "position": "top",
        "content": [
          "<div class='bg-white' style='padding: 30px; max-height: 300px; width: 60%; margin: 0 auto'><img style='max-width: 100%; max-height: 100%' src='/assets/logo-primary.svg'> </div>"
        ]
        },
        {
        "position": "bottom",
        "content": [
          "<div class='text-center flex flex-col py-4'><a class='text-lg font-bold text-primary' href='mailto:office@chapmanproperty.co.nz'>office@chapmanproperty.co.nz</a><br><a class='text-lg font-bold text-primary' href='tel:0273382502'>027 338 2502</a></div>"
        ]
        }
      ],
    });
    const api = menu.API;

    document.querySelector("#mburger")
      .addEventListener(
        "click", (evnt) => {
          evnt.preventDefault();
          api.open();
        }
      );
  }
);


//import 'mhead-js';
/*
// Mhead Configuration
document.addEventListener(
  "DOMContentLoaded", () => {
    const menu = new Mhead( "#nav-primary", {
             head: 0
            });
        }
    );*/